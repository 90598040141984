import { Pipe, PipeTransform } from '@angular/core';
import { Mentor } from '@features/mentors/models/Mentors.model';
import { SchoolClass } from '@features/school-class/models/school-class.model';

@Pipe({
  name: 'checkAllClasses'
})
export class CheckAllClassesPipe implements PipeTransform {
  public transform(mentor: Mentor, allSchoolClasses: SchoolClass[], grade: string | number): boolean {
    let schoolClasses: SchoolClass[] = mentor.schoolClasses;

    if (typeof grade === 'number') {
      schoolClasses = mentor.schoolClasses.filter(schoolClass => schoolClass.grade === grade);
    }

    return schoolClasses?.length === allSchoolClasses?.length;
  }
}
