import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'cacModelLogDiff',
})
export class ModelLogDiffPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {
  }

  public transform(value: string | number, dateFormat: string): string | number {
    if (isNaN(Date.parse(value as string))) {
      return value;
    }

    return this.datePipe.transform(value, dateFormat);
  }
}
